import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { RaterContext } from "../../RaterV3";
import dateformat from "dateformat";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2),
  },
}));

const employeeOptions = [
  { label: "Employee Only (EE)", value: "EE" },
  { label: "Employee plus Spouse (ES)", value: "ES" },
  { label: "Employee plus Children (EC)", value: "EC" },
  { label: "Employee plus Family (EF)", value: "EF" },
];

const genderOptions = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

const selectStyles = {
  menu: (styles) => ({ ...styles, zIndex: "1 !important" }),
  input: (styles) => ({ ...styles }),
  control: (styles) => ({ ...styles, color: "white" }),
};

export default function CensusForm(props) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [coverage, setCoverage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [added, setAdded] = useState(false);
  const { census, setCensus } = React.useContext(RaterContext);

  console.log("CENSUS ONCE CENSUS FORM LOADS: ", census);
  React.useEffect(() => {
    if (name === "" || dob === "" || gender === "" || coverage === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [name, dob, gender, coverage]);

  const addEmployee = () => {
    let date = dateformat(dob, "mm/dd/yyyy", true);
    let employee = { name, dob: date, gender: gender.value, coverage: coverage.value };

    // Handle the new census object structure
    let newCensus = {
      data: census.data ? [...census.data, employee] : [employee],
      columns: census.columns || ["name", "dob", "gender", "coverage"],
    };

    setCensus(newCensus);
    setName("");
    setDob("");
    setGender("");
    setCoverage("");
    setAdded(true);
  };

  const resetFocus = (input) => {
    const setFocus = (i) => {
      if (i && added) {
        setTimeout(() => {
          input.focus();
        }, 0);
        setAdded(false);
      }
    };
    setFocus(input);
  };

  return (
    <Grid container item xs={12} spacing={1} justify={"center"} alignItems={"flex-start"}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant={"h5"} align={"center"}>
          Add a new employee
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          autoFocus
          value={name}
          inputRef={resetFocus}
          fullWidth
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          label={"Name"}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Gender"
          placeholder="Gender"
          styles={selectStyles}
          options={genderOptions}
          onChange={(x) => setGender(x)}
          value={gender}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          label="Coverage"
          placeholder="Coverage"
          styles={selectStyles}
          options={employeeOptions}
          onChange={(x) => setCoverage(x)}
          value={coverage}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={dob}
          fullWidth
          type="date"
          variant="outlined"
          onChange={(e) => setDob(e.target.value)}
          helperText={"DOB"}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant={"contained"}
          onClick={addEmployee}
          disabled={disabled}
          color="primary"
          style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
          aria-label="add"
        >
          Add employee
        </Button>
      </Grid>
    </Grid>
  );
}
