import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import _ from "lodash";

/*NOTE:
This is the GAP Rate parser. If there are composite rates, those are parsed first and placed at the top.
The rest of the rates are conditionally checked for and laid out below.
 */
export default function Gap(props) {
  let compositeEqualsZero = props.data.composite ? props.data.composite.insuredOnly === 0 : false;

  const getDiscount = (discount) => {
    if (discount != -1) {
      discount = (100 - discount) / 100;
    }

    if (props.state === "MI" && props.provider === "shelter") {
      discount = 1;
    }
    if (props.state === "AL" && props.provider === "nationwide") {
      if (discount > 10) discount = 10;
    }

    if (props.sent) discount = -1;

    return discount == -1 ? 1 : discount;
  };

  console.log("this is props");

  console.log(props);

  const discount = getDiscount(props.discounts[props.provider]);

  const renderRates = (label, dataKey) => {
    // console.log("this is dataKey");
    // console.log(dataKey);
    // console.log("this is label");
    // console.log(label);
    const data = props.data[dataKey];

    //console.log("this is data");
    // console.log(data);

    // console.log(data.insuredOnly);

    if (!data) return null;

    return (
      <>
        <Grid item xs={12}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
            ${(data.insuredOnly * discount + props.fees).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
            ${(data.insuredPlusSpouse * discount + props.fees).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
            ${(data.insuredPlusChildren * discount + props.fees).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
            ${(data.insuredPlusFamily * discount + props.fees).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </>
    );
  };

  // if (props.provider === "everest_gap") {
  //   return (
  //     <>
  //       <Grid item xs={12}>
  //         <Typography align={"center"} style={{ fontWeight: "bold" }}>
  //           Everest GAP Rates
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={12}>
  //         <Divider />
  //       </Grid>
  //       {renderRates("Composite", "composite")}
  //       {renderRates("Age 50-59 (6 Age)", "sixAgeFiftyToFiftyNine")}
  //       {renderRates("Age 40-49 (6 Age)", "sixAgeFortyToFortyNine")}
  //       {renderRates("Age 65+ (6 Age)", "sixAgeSixtyFivePlus")}
  //       {renderRates("Age 60-69 (6 Age)", "sixAgeSixtyToSixtyNine")}
  //       {renderRates("Age 30-39 (6 Age)", "sixAgeThirtyToThirtyNine")}
  //       {renderRates("Age < 30 (6 Age)", "sixAgeUnderThirty")}
  //       {renderRates("Age 50+ (3 Age)", "threeAgeFiftyPlus")}
  //       {renderRates("Age 40-49 (3 Age)", "threeAgeFortyToFortyNine")}
  //       {renderRates("Age < 40 (3 Age)", "threeAgeUnderForty")}
  //       {renderRates("Age 55+ (2 Age)", "twoAgeFiftyFivePlus")}
  //       {renderRates("Age < 55 (2 Age)", "twoAgeUnderFiftyFive")}
  //     </>
  //   );
  // }

  return (
    <>
      {props.data.composite && !compositeEqualsZero ? (
        <>
          <Grid item xs={12}>
            <Typography align={"center"} style={{ fontWeight: "bold" }}>
              Standard Rates
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"}>
              {"< 40"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"}>
              40 - 49
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"}>
              50 +
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"right"} variant={"body2"} style={{ fontWeight: "bold" }}>
              Composite
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>Insured Only</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
              ${(props.data.underForty.insuredOnly * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
              ${(props.data.fortyToFortyNine.insuredOnly * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} color={"#0C2659"}>
              ${(props.data.fiftyPlus.insuredOnly * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"right"} variant={"body2"} style={{ color: "#0C2659", fontWeight: "bold" }}>
              ${(props.data.composite.insuredOnly * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {!_.isUndefined(props.data.underForty.insuredPlusSpouse) && (
            <>
              <Grid item xs={12}>
                <Typography>Insured Plus Spouse</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.underForty.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fortyToFortyNine.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fiftyPlus.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"right"} variant={"body2"} style={{ color: "#0C2659", fontWeight: "bold" }}>
                  ${(props.data.composite.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {!_.isUndefined(props.data.underForty.insuredPlusChildren) && (
            <>
              <Grid item xs={12}>
                <Typography>Insured Plus Children</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.underForty.insuredPlusChildren * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fortyToFortyNine.insuredPlusChildren * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fiftyPlus.insuredPlusChildren * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"right"} variant={"body2"} style={{ color: "#0C2659", fontWeight: "bold" }}>
                  ${(props.data.composite.insuredPlusChildren * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {props.data.underForty.insuredPlusOne && (
            <>
              <Grid item xs={12}>
                <Typography>Insured Plus One</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.underForty.insuredPlusOne * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fortyToFortyNine.insuredPlusOne * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fiftyPlus.insuredPlusOne * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align={"right"} variant={"body2"} style={{ fontWeight: "bold" }}>
                  ${(props.data.composite.insuredPlusOne * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography>Insured Plus Family</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
              ${(props.data.underForty.insuredPlusFamily * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
              ${(props.data.fortyToFortyNine.insuredPlusFamily * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
              ${(props.data.fiftyPlus.insuredPlusFamily * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography align={"right"} variant={"body2"} style={{ color: "#0C2659", fontWeight: "bold" }}>
              ${(props.data.composite.insuredPlusFamily * discount + props.fees).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      ) : (
        <>
          {props.data.fiftyPlus && (
            <>
              <Grid item xs={12}>
                <Typography align={"center"} style={{ fontWeight: "bold" }}>
                  Standard Rates
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3}></Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"}>
                  {"< 40"}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"}>
                  40 - 49
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"}>
                  50 +
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={3}>
                <Typography>Insured Only</Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.underForty.insuredOnly * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fortyToFortyNine.insuredOnly * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fiftyPlus.insuredOnly * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {!_.isUndefined(props.data.underForty.insuredPlusSpouse) && (
                <>
                  <Grid item md={12} xs={12} sm={12} lg={3}>
                    <Typography>Insured Plus Spouse</Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.underForty.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.fortyToFortyNine.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.fiftyPlus.insuredPlusSpouse * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {!_.isUndefined(props.data.underForty.insuredPlusChildren) && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Typography>Insured Plus Children</Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.underForty.insuredPlusChildren * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.fortyToFortyNine.insuredPlusChildren * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.fiftyPlus.insuredPlusChildren * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {props.data.underForty.insuredPlusOne && (
                <>
                  <Grid item md={12} xs={12} sm={12} lg={3}>
                    <Typography>Insured Plus One</Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.underForty.insuredPlusOne * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.fortyToFortyNine.insuredPlusOne * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item md={4} lg={3}>
                    <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                      ${(props.data.fiftyPlus.insuredPlusOne * discount + props.fees).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Typography>Insured Plus Family</Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.underForty.insuredPlusFamily * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fortyToFortyNine.insuredPlusFamily * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item md={4} lg={3}>
                <Typography align={"center"} variant={"body2"} style={{ color: "#0C2659" }}>
                  ${(props.data.fiftyPlus.insuredPlusFamily * discount + props.fees).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
