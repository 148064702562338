/* 

So these are the payloads that we send to the rating-engine for all the availible Quick Quote products. If we change the allowed inputs in the rating-engine,
we may need to change these values as well.

*/

const shelterPayload = {
  state: "GA",
  sicCode: 111,
  groupEffectiveDate: `10/01/2022`,
  employerContributionPercent: 1.0,
  familyDeductibleMultiplier: "2X",
  combinedBenefit: 5000,
  familyBenefitMultiplier: "Family Max at 2X Individual",
  deductible: 250,
  coinsurancePercent: 0,
  physiciansBenefitSameAsAnyOther: "Not Covered",
  physiciansBenefit: "Not Covered",
  rxCoverageType: "Not Covered",
  hasMentalHealthAndSubstanceAbuseCoverage: true,
};

const nationwidePayload = {
  state: "AL",
  groupEffectiveDate: "01/01/2021",
  sicCode: 111,
  deductible: 250,
  employerContributionPercent: 1.0,
  familyBenefitMultiplier: "2x",
  combinedBenefit: 5000,
  eligibleEmployees: 120,
  ratingMethod: "Composite",
  hasRxBenefit: false,
  hasPhysiciansBenefit: false,
  includeDeductible: true,
  physiciansBenefitPerVisit: 0,
  coinsurancePercent: 0,
  familyDeductibleMultiplier: "2x",
  hasMentalHealthAndSubstanceAbuseCoverage: true,
};

const zurichPayload = {
  groupEffectiveDate: "08/01/2022",
  state: "GA",
  sicCode: 111,
  employerContributionPercent: 1.0,
  deductibleWaivedForAccident: false,
  familyDeductibleMultiplier: "2x",
  deductible: 250,
  combinedBenefit: 250,
  // hasPhysiciansBenefit: false,
  coinsurancePercent: 1,
  familyBenefitMultiplier: "2x",
  physiciansBenefitPerVisit: 0,
  physiciansAnnualVisits: 0,
  officeVisitFamilyMax: "No Limit",
};

const markelPayload = {
  groupEffectiveDate: "09/01/2022",
  state: "AL",
  sicCode: 8011,
  eligibleEmployeesSelection: "Less Than 100",
  employerContributionPercent: 1,
  deductible: 250,
  familyDeductibleMultiplier: "No Limit",
  familyBenefitMultiplier: "2x",
  combinedBenefit: 7000,
  physiciansOfficeCopayOption: false,
  coinsurancePercent: 0,
  hasPhysiciansBenefit: false,
  hasMentalHealthAndSubstanceAbuseCoverage: true,
};

// const everest_gapPayload = {
//   groupEffectiveDate: "09/01/2022",
//   state: "AL",
//   sicCode: 8011,
//   eligibleEmployeesSelection: "Less Than 100",
//   employerContributionPercent: 1,
//   deductible: 250,
//   familyDeductibleMultiplier: "No Limit",
//   familyBenefitMultiplier: "2x",
//   combinedBenefit: 7000,
//   physiciansOfficeCopayOption: false,
//   coinsurancePercent: 0,
//   hasPhysiciansBenefit: false,
//   hasMentalHealthAndSubstanceAbuseCoverage: true,
// };

// physiciansFamilyLimitMultiplier needs to be No Limit
const chubbPayload = {
  groupEffectiveDate: "01/01/2022",
  state: "CA",
  sicCode: 6311,
  employerContributionPercent: 1,
  employerContributionAppliesTo: "EE's & Dep's",
  combinedBenefit: 7000,
  coinsurancePercent: 1.0,
  ipOpDeductible: 250,
  baseMedFamilyBenefitMultiplier: "2X Traditional Ded",
  gapMedFamilyBenefitMultiplier: "2X Traditional Ded",
  hasPhysiciansBenefit: false,
};

const everest_gapPayload = {
  state: "GA",
  groupEffectiveDate: "08/01/2024",
  policyRenewalDate: "08/01/2024",
  employerContributionPercent: 0.55,
  employeeParticipation: 0.55,
  enrolledEmployees: 50,
  minimumParticipation: "Waived",
  deductible: 250,
  combinedBenefit: 250,
  sicCode: 111,
  employerGroup: "Employer",
  annualTrend: 0.05,
  familyDeductibleMultiplier: "2x",
  deductibleWaivedForAccidents: "No",
  excludePrescriptionDrug: "No",
  mentalHealthAndSubstanceAbuse: "Mental Health and Substance Abuse Excluded",
  benefitType: "Inpatient & Outpatient",
  coinsurancePercent: 0.05,
  coinsuranceWaivedForAccidents: "Yes",
  familyBenefitMultiplier: "2x",
  hasPhysiciansBenefitIPOP: "No",
  doctorsOfficeVisitBenefit: "No",
  doctorsOfficeVisitBenefitAmount: 5,
  physiciansAnnualVisits: 3,
  excludeTreatment: "No",
  applyPlanDeductibleDoctorVisit: "No",
  ambulanceBenefit: "Yes",
  ambulanceBenefitAmount: 150,
  applyPlanDeductibleAmbulance: "No",
  outpatientDurableMedicalEquipment: "No",
  outpatientDurableMedicalEquipmentAmount: 15,
  applyPlanDeductibleDurableMedicalEquipment: "No",
  outpatientLaboratoryTesting: "No",
  outpatientLaboratoryTestingAmount: 150,
  applyPlanDeductibleLaboratoryTesting: "No",
};

const ihpPayload = {
  state: "GA",
  sicCode: 111,
  groupEffectiveDate: `10/01/2022`,
  employerContributionPercent: 5.0,
  rateSelection: "IHP Basic",
};

export {
  shelterPayload,
  zurichPayload,
  markelPayload,
  chubbPayload,
  nationwidePayload,
  ihpPayload,
  everest_gapPayload,
};
