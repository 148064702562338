import React from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import EditEmployee from "./EditEmployee";
import { RaterContext } from "../../RaterV3";

export default function CensusTable() {
  const { census, setCensus, groupName } = React.useContext(RaterContext);
  const [data, setData] = React.useState([]);
  const [primed, setPrimed] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [employee, setEmployee] = React.useState({});

  function saveEmployee(index, employee) {
    let arr = [...census.data];
    arr[index] = employee;
    setCensus({
      data: arr,
      columns: census.columns,
    });
  }

  React.useEffect(() => {
    if ((census && census.data && census.data.length > 0) || primed) {
      setData(census.data);
      setPrimed(true);
    }
  }, [census]);
  return (
    <Grid item xs={12}>
      <MaterialTable
        columns={[
          { title: "Name", field: "name" },
          { title: "DOB", field: "dob" },
          { title: "Gender", field: "gender" },
          { title: "Coverage", field: "coverage" },
        ]}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  let newData = [...census.data];
                  const index = newData.indexOf(oldData);
                  newData.splice(index, 1);
                  setCensus({
                    data: newData,
                    columns: census.columns,
                  });
                }
                resolve();
              }, 1000);
            }),
        }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
          pageSize: 15,
          pageSizeOptions: [15, 30, 50],
          padding: "dense",
          exportFileName: `${groupName}census`,
          exportAllData: true,
        }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Employee",
            onClick: (event, rowData) => {
              setEmployee(rowData);
              setEditing(true);
            },
          },
        ]}
        data={data}
        title="Census Data"
      />
      <EditEmployee open={editing} handleClose={() => setEditing(false)} save={saveEmployee} employee={employee} />
    </Grid>
  );
}
