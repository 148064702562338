import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { RaterContext } from "../RaterV3";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  shelterPayload,
  zurichPayload,
  markelPayload,
  chubbPayload,
  nationwidePayload,
  everest_gapPayload,
} from "../QuickQuote/payloads";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rename = () => {
  const values = {
    employerContributionPercent: "Employer Contribution",
    familyDeductibleMultiplier: "Family Max Deductible",
    combinedBenefit: "Combined Benifit",
    deductible: "Deductible",
    coinsurancePercent: "Coinsurence Percent",
    physiciansBenefitSameAsAnyOther: "Family Deductible Multiplier",
    rxCoverageType: "Rx Coverage Type",
    hasMentalHealthAndSubstanceAbuseCoverage: "Mental Health and Substance Abuse",
  };
};

export default function PayloadsModal(props) {
  const quickQuotePlans = {
    Shelter: shelterPayload,
    Zurich: zurichPayload,
    Markel: markelPayload,
    Chubb: chubbPayload,
    Nationwide: nationwidePayload,
    Everest_GAP: everest_gapPayload,
  };

  const rename = (field) => {
    const values = {
      employerContributionPercent: "Employer Contribution",
      familyDeductibleMultiplier: "Family Max Deductible",
      familyBenefitMultiplier: "Family Benifit Multiplier",
      combinedBenefit: "Combined Benifit",
      deductible: "Deductible",
      coinsurancePercent: "Coinsurence Percent",
      physiciansBenefitSameAsAnyOther: "Family Deductible Multiplier",
      rxCoverageType: "Rx Coverage Type",
      deductibleWaivedForAccident: "Deductible Waived for Accident",
      physiciansBenefitPerVisit: "Physicians Benefit Per Visit",
      physiciansAnnualVisits: "Physicians Annual Visit",
      officeVisitFamilyMax: "Office Visit Family Max",
      familyBenefitMultiplier: "Family Benefit Multiplier",
      physiciansOfficeCopayOption: "Physicians Office Copay Option",
      hasPhysiciansBenefit: "Physicians Benefit",
      employerContributionAppliesTo: "Employer Contribution Applies To",
      ipOpDeductible: "Deductible",
      baseMedFamilyBenefitMultiplier: "Base Med Family Benefit Multiplier",
      eligibleEmployees: "Eligible Employees",
      ratingMethod: "Rating Method",
      hasRxBenefit: "Rx Benefit",
      hasPhysiciansBenefit: "Physicians Benefit",
      includeDeductible: "Include Deductible",
      hasMentalHealthAndSubstanceAbuseCoverage: "Mental Health and Substance Abuse",
      default: "field",
    };

    return values[field] || values["default"];
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton onClick={props.close} color={"#0C2659"} style={{ position: "absolute", right: 10, top: 10 }}>
          <CloseIcon style={{ backgroundColor: "#0C2659", color: "white" }} />
        </IconButton>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <div style={{ display: "flex", alignContent: "flex-start" }}>
            <div style={{ marginLeft: 5 }}>
              <Typography variant={"h5"} color={"primary"} style={{ fontWeight: "bold" }}>
                Quick Quote Plan Options
              </Typography>
            </div>
          </div>
          {/* <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <Typography variant={"body2"}>
            Add description for Quick Quote Plans if wanted.
          </Typography> */}
        </DialogTitle>
        <DialogContent>
          <Divider style={{ marginBottom: 10 }} />
          <Grid item xs={12}>
            {/* <Collapse in={showInputs}> */}

            {Object.entries(quickQuotePlans).map(([plan, payload]) => {
              return (
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }} variant={"h6"}>
                      {plan} Plan
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {Object.entries(payload).map(([field, value]) => {
                    if (
                      field !== "groupEffectiveDate" &&
                      field !== "zipCode" &&
                      field !== "sicCode" &&
                      field !== "state"
                    ) {
                      return (
                        <React.Fragment key={JSON.stringify(rename(field))}>
                          <Grid item xs={6}>
                            <Typography variant={"caption"}>{rename(field)}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography color={"#0C2659"} align={"right"} variant={"body2"}>
                              {!_.isUndefined(value)
                                ? typeof value === "object"
                                  ? value
                                  : _.upperFirst(value.toString())
                                : "Yes"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </React.Fragment>
                      );
                    } else return <React.Fragment key={JSON.stringify(field)}></React.Fragment>;
                  })}
                </Grid>
              );
            })}

            {/* </Collapse> */}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
