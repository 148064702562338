import React from "react";
import { RatingEngineContext } from "react-rating-engine";
import { RaterContext } from "../../RaterV3";
import { FirebaseDB, FirebaseContext } from "react-ugp-firebase";
import { getSupportBenefits } from "../../../utils/engine";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import Slide from "react-reveal/Slide";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import RatesModal from "../../Modals/RatesModal";
import { makeStyles } from "@material-ui/core/styles";
import InfoModal from "../../Modals/InfoModal";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import GeneralModal from "../../Modals/GeneralModal";
import AddonsModal from "../../Modals/AddonsModal";
import QuickQuoteModal from "../../Modals/QuickQuoteModal";
import IHPQuickQuoteModal from "../../Modals/IHPQuickQuoteModal";
import ShelterWarningModal from "../../Modals/ShelterModal";
import NoPreferenceControl from "./NoPreferenceControl";
import Field from "./Field";
import { prettifyProduct, prettifyProvider, prettifyPlan } from "../../../utils/prettifyFunctions";
import IconButton from "@material-ui/core/IconButton";
import { getQuickQuotes } from "../../QuickQuote/quickquoteservice";
import { getIHPQuickQuotes } from "../../QuickQuote/ihpservice";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  link: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  },
  icon: {
    "&:hover": {
      cursor: "pointer",
      size: 30,
    },
  },
}));

export default function ConfigureProduct(props) {
  const classes = useStyles();
  const [provider, setProvider] = React.useState({});
  const [providers, setProviders] = React.useState([]);
  const [plans, setPlans] = React.useState([]);
  const [plan, setPlan] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [brokerFee, setBrokerFee] = React.useState(0);
  const [openRates, setOpenRates] = React.useState(false);
  const [benefits, setBenefits] = React.useState(undefined);
  const [benefitsOpen, setBenefitsOpen] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openQuickQuoteModal, setOpenQuickQuoteModal] = React.useState(false);
  const [openIHPQuickQuoteModal, setOpenIHPQuickQuoteModal] = React.useState(false);
  const [openASTWarningModal, setOpenASTWarningModal] = React.useState(false);
  const [noPlansAvailable, setNoPlansAvailable] = React.useState(false);
  const [isGap, setIsGap] = React.useState(false);
  //const [outpatientBenefit, setOutpatientBenefit] = React.useState(false);

  const [hasTriggered, setHasTriggered] = React.useState(false);

  /* ENABLE ADDONS */
  const enableAddons = false;

  const [showAddons, setShowAddons] = React.useState(false);

  const [noPreferenceDisabled, setNoPreferenceDisabled] = React.useState(true);
  const { walking, fields, setConfiguration, reset, rateData, typesArray } = React.useContext(RatingEngineContext);
  const {
    log,
    webError,
    productDescriptionMapping,
    setLoading,
    gapCensus,
    census,
    uiDelegate,
    zipCode,
    sicCode,
    groupEffectiveDate,
    products,
    setProducts,
    requiredLevel,
    scrollToBottom,
    state,
    fourTierCensus,
    mecBroker,
    ihpBroker,
    majorBroker,
    advantageBroker,
    setError,
    setMessage,
    dentalBroker,
    setHasSubmit,
    total,
    quickQuoteDeductible,
    quickQuoteCombinedBenefit,
    selectedBroker,
    discounts,
    setDiscounts,
    activeProduct,
    ihpBrokerFee,
    shelterPayloadTracker,
    setSheltPayload,
    nationPayloadTracker,
    setNationPayload,
    zurichPayloadTracker,
    setZurPayload,
    setAdminFees,
    adminFees,
    markelPayloadTracker,
    setMarkelPayload,
    chubbPayloadTracker,
    setChubbPayload,
    everest_gapPayloadTracker,
    setEverest_gapPayload,
    IHPPayloadTracker,
    setIHPPayload,
    isQQ,
    setQQ,
    userCRMid,
    setUserCRMid,
    canabisChecker,
    hsaCompatibility,
    setHsaCompatibility,
  } = React.useContext(RaterContext);

  const [defaults, setDefaults] = React.useState({
    sicCode,
    groupEffectiveDate,
    zipCode,
    state,
  });
  const { restConfig, user, isUserLoading } = React.useContext(FirebaseContext);

  const userRole = isUserLoading ? "" : user.roles;

  console.log("TESTING USERROLE");
  console.log(userRole);

  const adminFeeDefaults = {
    2024: {
      accident: 0,
      mec: 31,
      extras_voluntary: 6.11,
      dental: 10,
      std: 0,
      rx: 0,
      extras_employerpaid: 2.11,
      hospital: 0,
      ltdmed: 25,
      major_medical: 60,
      vision: 0,
      gap: 15.99,
      life: 0,
      ltd: 0,
      ihp: 0,
    },
    2025: {
      accident: 0,
      mec: 31,
      extras_voluntary: 6.11,
      dental: 10,
      std: 0,
      rx: 0,
      extras_employerpaid: 2.11,
      hospital: 0,
      ltdmed: 25,
      major_medical: 60,
      vision: 0,
      gap: 17.5, // Updated GAP fee for 2025
      life: 0,
      ltd: 0,
      ihp: 0,
    },
  };

  React.useEffect(() => {
    const currentYear = new Date().getFullYear();
    const selectedYear = currentYear >= 2025 ? 2025 : 2024; // Default to 2024 if year < 2025
    setAdminFees(adminFeeDefaults[selectedYear]);
  }, []);

  console.log("CHECKING FOR FIELDS part 1 ????");

  console.log(fields);

  const prevFields = usePrevious(fields);

  React.useEffect(() => {
    const currentCoinsurance = fields?.find((f) => f.name === "coinsurancePercent")?.value;
    const previousCoinsurance = prevFields?.find((f) => f.name === "coinsurancePercent")?.value;

    if (fields && currentCoinsurance && currentCoinsurance !== previousCoinsurance) {
      handleCoinsuranceBenefit();
    }
  }, [fields]);

  // React.useEffect(() => {
  //   // Find the deductible field
  //   const deductibleField = fields?.find((field) => field.name === "deductible");
  //   if (deductibleField && deductibleField.value !== undefined) {
  //     if (deductibleField.value < 1650) {
  //       setHsaCompatibility(false); // Automatically default to No
  //     } else {
  //       setHsaCompatibility(undefined); // Allow user to select
  //     }
  //   }
  // }, [fields]);

  // Helper hook for tracking previous value
  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // if (userRole.includes("employee")) {
  /*NOTE:
   * This useEffect function is run anytime the props change through the parent of Products.js. This will
   * happen as a result of the user clicking a new product type or a previously created plan is "edited"
   * by the user. This function resets the internal state of the ConfigureProduct component and uses the passed
   * in data to set up the state to interact with the react-rating component properly. This function can also often
   * set a useEffect chain reaction that starts here and ends by calling the setConfig function.
   * */
  React.useEffect(() => {
    resetConfiguration();
  }, [props]);

  /* NOTE:
   * Anytime the provider is changed either through user selection or automatically, we create a list of plans.
   * If the list only has a single plan in it then that plan is automatically set. If there is a plan passed in as
   * a prop then that plan takes priority, this happens when a user loads in a previously saved card.
   * */
  React.useEffect(() => {
    providerChange();
  }, [provider]);

  //Once the plan is selected or automatically set we call setConfiguration from the react-rating component.
  React.useEffect(() => {
    setConfig();
  }, [plan]);

  /*NOTE
    Anytime a relevant field is changed that determines a user's progress, we recalculate their progress to make sure all progress bars
    are properly updated.
     */
  React.useEffect(() => {
    calculateProgress();
  }, [provider, plan, fields]);

  React.useEffect(() => {
    if (provider !== "" && plan !== "") {
      updateProductManager();
    }
  }, [provider, plan, fields, rateData, benefits, progress]);

  /*NOTE
     Anytime the rateData object changes if rates are available and the current plan has support benefits,
     the API call is made to get those benefits.
     */
  React.useEffect(() => {
    checkSupportBenefits();
  }, [rateData]);

  React.useEffect(() => {
    checkIsGap();
  }, [providers]);

  // TODO:

  React.useEffect(() => {
    console.log("testing in normal discounts for config");
    const getDiscounts = async () => {
      const brokerRef = FirebaseDB.collection("discounts").doc(selectedBroker);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (selectedBroker != undefined) {
      getDiscounts();
    }
  }, []);

  // console.log("this is in config prod crmid");

  // console.log(userCRMid);

  React.useEffect(() => {
    console.log("testing in discounts for config");
    const getDiscounts = async () => {
      console.log("trigger discounts in config prodcut");
      const brokerRef = FirebaseDB.collection("discounts").doc(userCRMid);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (userCRMid !== "" && user.roles && user.roles.includes("broker")) {
      getDiscounts();
    }
  }, []);

  const handleCoinsuranceBenefit = () => {
    // Your function logic here
    setConfig();
    console.log("Coinsurance benefit field detected");
  };
  /* 
    This function is used to filter out Providers from the carrier selection after they select their product. It was reworked so you
    can filter each product separatly with a condition.
  */

  const filterProviders = (roles, providers) => {
    const excludeProviders = [];

    // states to filter
    const markelStates = ["IL", "ME", "MA", "VA"];
    const zurichStates = ["FL"];

    var majorMedicalStates = ["AK", "CA", "CT", "DC", "HI", "MI", "MN", "MT", "NH", "NY", "RI", "SD", "VT", "WA", "WI"];

    // Markel restricted SIC codes
    const markelRestrictedSicCodes = [752, 783, 781];

    //const everestRestrictedSicCodes = [8111];

    const everestRestrictedSicCodes = [
      711, 721, 722, 723, 724, 741, 742, 751, 752, 761, 762, 781, 782, 783, 811, 831, 851, 1011, 1021, 1031, 1041, 1044,
      1061, 1081, 1094, 1099, 1221, 1222, 1231, 1241, 1311, 1321, 1381, 1382, 1389, 1521, 1522, 1531, 1541, 1542, 1611,
      1622, 1623, 1629, 1711, 1721, 1731, 1741, 1742, 1743, 1751, 1752, 1761, 1771, 1781, 1791, 1793, 1794, 1795, 1796,
      1799, 2411, 2421, 2426, 2429, 3312, 3313, 3315, 3316, 3317, 3321, 3322, 3324, 3325, 3482, 3483, 3484, 3489, 4011,
      4013, 4111, 4119, 4121, 4131, 4141, 4142, 4151, 4173, 4212, 4213, 4214, 4215, 4221, 4222, 4225, 4226, 4231, 4311,
      4412, 4424, 4432, 4449, 4481, 4482, 4489, 4491, 4492, 4493, 4499, 7941, 7948, 7991, 7992, 7993, 7996, 7997, 7999,
      8011, 8021, 8031, 8041, 8042, 8043, 8049, 8051, 8052, 8059, 8062, 8063, 8069, 8071, 8072, 8082, 8092, 8093, 8099,
      8111, 8611, 8621, 8631, 8641, 8651, 8661, 8699, 8811, 9211, 9221, 9222, 9223, 9224, 9229,
    ];

    if (majorMedicalStates.includes(state)) {
      console.log("Census total is " + total + ". Filtering " + state);
      excludeProviders.push("ast");
    }

    if (canabisChecker) {
      excludeProviders.push("nationwide");
    }

    if (total < 10) {
      excludeProviders.push("everest_gap");
    } else if (!userRole.includes("ihp_agent")) {
      excludeProviders.push("everest_gap");
    }

    // restricted sic codes

    const zurichRestrictedSicCodes = [
      1011, 1241, 1411, 1499, 1742, 2892, 2895, 3292, 3296, 3312, 3321, 3322, 3323, 3324, 3325, 3365, 3366, 3367, 3368,
      3369, 3482, 3483, 3484, 3485, 3486, 3486, 3487, 3488, 3489, 3523, 3559, 3567, 3568, 3569, 3731, 3732, 4512, 4581,
      5033, 6035, 6036, 6061, 6062, 6111, 7363, 7941, 8631, 9711,
    ];

    if (markelStates.includes(state) && total <= 51) {
      console.log("Census total is " + total + ". Filtering " + state);
      excludeProviders.push("markel");
    }

    if (markelRestrictedSicCodes.includes(sicCode)) {
      console.log("Invalid SicCode for Markel");
      excludeProviders.push("markel");
    }

    if (everestRestrictedSicCodes.includes(sicCode)) {
      console.log("Invalid SicCode for Everest GAP");
      excludeProviders.push("everest_gap");
    }

    if (
      (zurichStates.includes(state) && total <= 51 && props.name.toLowerCase() === "gap") ||
      zurichRestrictedSicCodes.includes(sicCode)
    ) {
      if (zurichStates.includes(state) && total <= 51) {
        console.log("Census total is " + total + ". Filtering " + state);
      } else if (zurichRestrictedSicCodes.includes(sicCode)) {
        console.log("Invalid SicCode for Zurich");
      }
      excludeProviders.push("zurich");
    }

    if (total > 350) {
      console.log("Census total is " + total + ". Filtering shelter");
      excludeProviders.push("shelter");
    }

    // Define roles that should not have access to certain providers
    const excludedRoles = ["accounting", "commissions", "employee", "admin", "billing", "ihp_agent"];

    // Check if the user has any of the excluded roles
    const hasExcludedRole = excludedRoles.some((role) => userRole.includes(role));

    // If the user does NOT have any excluded roles, include "chubb" and "nationwide"
    if (!hasExcludedRole) {
      excludeProviders.push("chubb", "nationwide");
    }

    return _.filter(_.toPairs(providers), (p) => !excludeProviders.includes(p[0]));
  };

  /*NOTE:
    Triggered by a useEffect that has all props as a dependency. Depending on the passed down props, this function will reset the configuration
    accordingly and possibly trigger the provider and plan useEffects which eventually leads to setting the configuration with the new data.
     */
  const resetConfiguration = () => {
    setLoading(true);
    if (
      plan.value &&
      provider.value &&
      props.plan === plan.value.plan &&
      props.provider === provider.value.label &&
      props.fields &&
      props.rateData &&
      props.rateData.continue
    ) {
      let defs = {};
      props.fields.forEach((field) => {
        defs[field.name] = typeof field.value === "object" ? field.value.index : field.value;
      });
      reset(defs);
    } else {
      setProvider("");
      setPlan("");
      setBenefits("");
      setPlans([]);
      setNoPreferenceDisabled(true);
      if (props.name === "mec" || props.name === "emec") {
        setBrokerFee(mecBroker);
        console.log("setting mec fee");
      } else if (props.name === "ihp") {
        setBrokerFee(ihpBroker);
        console.log("setting ihp fee" + ihpBroker);
      } else if (props.name === "major_medical") setBrokerFee(majorBroker);
      else if (props.name === "everest") setBrokerFee(majorBroker);
      else if (props.name === "extras") setBrokerFee(advantageBroker);
      else if (props.name === "dental") setBrokerFee(dentalBroker);
      else setBrokerFee(0);
      if (props.data && props.data.providers) {
        if (props.name.toLowerCase() !== "gap") {
          //Mapping the provider list to fit into the react-select format but keep all the necessary data for future configuration
          const preppedProviders = filterProviders(user.roles, props.data.providers);

          let providers = _.sortBy(
            preppedProviders.map((provider) => {
              return {
                label: prettifyProvider(provider[0]),
                value: { providers: provider[1], label: provider[0] },
              };
            }),
            (p) => p.label
          );
          setProviders(providers);
          if (providers.length === 1) {
            setProvider(providers[0]);
          }
        } else {
          /* NOTE: For now, we only want employees to see the Markel opiton */

          const preppedProviders = filterProviders(user.roles, props.data.providers);

          let providers = _.sortBy(
            preppedProviders.map((provider) => {
              return {
                label: prettifyProvider(provider[0]),
                value: { providers: provider[1], label: provider[0] },
              };
            }),
            (p) => p.label
          );

          setProviders(providers);

          if (providers.length === 1) {
            setProvider(providers[0]);
          }
        }
      }
      if (props.fields && props.provider && props.plan && props.rateData && props.rateData.continue) {
        let defs = {};
        props.fields.forEach((field) => {
          defs[field.name] = typeof field.value === "object" ? field.value.index : field.value;
        });
        setDefaults(defs);
        let index = _.findIndex(providers, (x) => {
          return x.value.label === props.provider;
        });
        if (index > -1) setProvider(providers[index]);
        if (props.plan && index > -1) {
          let arr = [];
          providers[index].value.providers.forEach((plan) => {
            arr.push({ label: prettifyPlan(plan.plan), value: plan });
          });
          let planIndex = _.findIndex(arr, (x) => {
            return x.value.plan === props.plan;
          });
          setPlan(planIndex > 0 ? arr[planIndex] : arr[0]);
        }
      } else setDefaults({ sicCode, zipCode, groupEffectiveDate, state });
    }
    setLoading(false);
  };

  /*NOTE:
    Triggered by a useEffect that has the plan as the dependency. Any time a plan is selected or automatically set, we
    call the setConfiguration function giving by the react-rating component.
     */
  const setConfig = () => {
    console.log("setting config");
    if (plan !== "" && provider !== "") {
      setLoading(true);
      let isMulti = props.name.toLowerCase() === "gap" && provider.label.toLowerCase() === "shelterpoint";
      let censusData =
        props.name.toLowerCase() === "gap" ||
        props.name.toLowerCase() === "rx" ||
        props.name.toLowerCase() === "travelers"
          ? gapCensus
          : requiredLevel !== 3
          ? fourTierCensus
          : census;

      // Only modify defaults for everest_gap specifically
      if (provider.value.label === "everest_gap") {
        const modifiedDefaults = { ...defaults };

        console.log("modifiedDefaults");
        console.log(modifiedDefaults);

        // Parse the groupEffectiveDate
        const [month, day, year] = modifiedDefaults.groupEffectiveDate.split("/");
        const effectiveDate = new Date(year, month - 1, day); // month is 0-based in JS Date

        // Add one year
        effectiveDate.setFullYear(effectiveDate.getFullYear() + 1);

        // Format back to MM/DD/YYYY
        const renewalDate = `${(effectiveDate.getMonth() + 1).toString().padStart(2, "0")}/${effectiveDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${effectiveDate.getFullYear()}`;

        modifiedDefaults.policyRenewalDate = renewalDate;

        // Check if fields exists before trying to use it
        if (fields && Array.isArray(fields)) {
          const coinsuranceInpatientField = fields.find((field) => field.name === "coinsurancePercent");

          console.log("coinsuranceInpatientField");
          console.log(coinsuranceInpatientField);
          if (coinsuranceInpatientField && coinsuranceInpatientField.value !== undefined) {
            modifiedDefaults.coinsuranceOutpatientBenefit = coinsuranceInpatientField.value;
          }
        }

        console.log("modifiedDefaults");
        console.log(modifiedDefaults);

        setConfiguration(uiDelegate, provider.value.label, plan.value.plan, censusData, modifiedDefaults, isMulti);
      } else {
        setConfiguration(uiDelegate, provider.value.label, plan.value.plan, censusData, defaults, isMulti);
      }

      setLoading(false);
    }
  };

  /*NOTE:
    Function triggered by useEffect that builds out all the available plans a user can select based on the provider
    that was selected. This is usually only going to come into play with GAP where all the providers have different
    plans that they offer.
     */
  const providerChange = () => {
    let arr = [];
    setNoPreferenceDisabled(true);
    if (provider.value && provider.value.providers) {
      provider.value.providers.forEach((plan) => {
        arr.push({ label: prettifyPlan(plan.plan), value: plan });
      });
      setPlans(arr);
      let index = _.findIndex(arr, (x) => {
        return x.value.plan === props.plan;
      });
      setPlan(index > 0 ? arr[index] : arr[0]);
    }
    setLoading(false);
  };

  const checkSupportBenefits = () => {
    if (rateData && rateData.ratesAvailable && plan.value && plan.value.supportsBenefitsAPI) {
      setLoading(true);
      let inputData = {};
      fields.forEach((field) => {
        inputData[field.name] =
          typeof field.value === "object"
            ? field.value.index
            : field.value === 0 &&
              field.name.toLowerCase().indexOf("percent") === -1 &&
              field.name.toLowerCase().indexOf("plan") === -1
            ? false
            : field.value === 1 &&
              field.name.toLowerCase().indexOf("percent") === -1 &&
              field.name.toLowerCase().indexOf("plan") === -1
            ? true
            : field.value;
      });
      let data = {
        inputData: inputData,
        censusData:
          props.name === "ltdmed" || props.name === "mec" || props.name === "emec" || props.name === "ihp"
            ? fourTierCensus
            : requiredLevel < 3
            ? gapCensus
            : census,
      };
      getSupportBenefits(data, restConfig.headers, provider.value.label, plan.value)
        .then((res) => {
          setLoading(false);
          setBenefits(res.data);
        })
        .catch((err) => {
          console.log(err);
          webError({
            text: "Something went wrong when trying to get support benefits",
            data,
            err,
            provider: provider.value.label,
            plan: plan.value,
          });
          setLoading(false);
        });
    }
  };

  console.log("CHECKING FOR FIELDS????");

  console.log(fields);

  /*NOTE:
    Simple function that is run using a useEffect that depends on all the important state variables.
    Anytime a major variable is changed then the global products object is updated accordingly to reflect
    the changes made.
     */
  const updateProductManager = () => {
    let obj = _.cloneDeep(products);
    if (provider !== "" && plan !== "") {
      let newFields = mapFields(fields);
      let newBenefits = benefits !== "" ? benefits : "No benefits";
      obj[props.name].provider = provider.value.label;
      obj[props.name].plan = plan.value.plan;
      obj[props.name].fields = newFields;
      obj[props.name].benefits = newBenefits;
      obj[props.name].rateData = rateData;
      obj[props.name].progress = progress;
    }
    setProducts(obj);
  };

  console.log("HEY WE JUST SET THE FIELD SCHEMA HERE");
  console.log(products);

  /*NOTE:
    mapFields takes the format of the fields value given by the rating engine contxt and transforms it into a simple format that we
    can use to easily display the data to the user.
     */
  const mapFields = (fields) => {
    if (fields && fields.length !== 0) {
      let newFields = [];
      fields.forEach((field) => {
        if (field.options && field.options.type === "indexedArray") {
          //We sort the options array based on the index to make sure the value selected matches the option in the array.
          let options = _.sortBy(field.options.options, (x) => x.index);

          newFields.push({
            name: field.name,
            desc: field.desc,
            value: options[field.value],
          });
        } else
          newFields.push({
            name: field.name,
            desc: field.desc,
            value: field.type === "Percent" ? field.value * 100 + "%" : field.value,
          });
      });
      return newFields;
    } else return null;
  };

  const saveProduct = (product) => {
    let obj = _.cloneDeep(products);
    let arr = obj[props.name].saved ? obj[props.name].saved : [];
    let isDuplicate = checkDuplicate(product);
    let shouldContinue = true; // Flag to determine if the rest of the code should run

    if (product.provider === "chubb") {
      console.log("checking chubb");
      product.fields.forEach((field) => {
        console.log("checking fields");
        console.log(field);
        if (field.name === "sicCode") {
          const sicCodeValues = [
            "1611",
            "1799",
            "912",
            "972",
            "4952",
            "4959",
            "3812",
            "4212",
            "4231",
            "2411",
            "1011",
            "1311",
            "1241",
            "1389",
            "9229",
            "7941",
            "9211",
            "922",
          ];
          const numericSicCodeValues = sicCodeValues.map(Number);
          if (numericSicCodeValues.includes(field.value)) {
            setError(true);
            setMessage(
              "Invalid sicCode and provider combination. Contact your OptiMed Representative for further assistance"
            );
            shouldContinue = false; // Stop the execution of the rest of the code
            return; // Exit the forEach loop
          }

          // ...other sicCode checks, if any...
        }

        // ...other field checks...
      });
    }

    if (shouldContinue) {
      // Continue only if the flag is true
      if (!isDuplicate) {
        arr.unshift(product);
        obj[props.name].saved = arr;
        setProducts(obj);
        log("product_saved", product);
        setTimeout(() => scrollToBottom(), 500);
      } else {
        setError(true);
        setMessage(
          "It looks like you are trying to save a duplicate plan. Please select different plan options and try again. If you have not already saved these options, please contact support."
        );
      }
    }
  };

  const saveProducts = (productArr) => {
    let obj = _.cloneDeep(products);
    let arr = obj[props.name].saved ? obj[props.name].saved : [];
    productArr.forEach((product) => {
      let isDuplicate = checkDuplicate();
      if (!isDuplicate) {
        arr.unshift(product);
      }
    });
    obj[props.name].saved = arr;
    setProducts(obj);
    setTimeout(() => scrollToBottom(), 500);
  };

  const isAddonProduct = props.name.toLowerCase() === "gap";

  const preSave = () => {
    if (enableAddons && isAddonProduct) {
      setShowAddons(enableAddons && true);
    } else {
      save([]);
    }
  };

  //Saves the plan configuration under the opportunity
  const save = (addons) => {
    setShowAddons(false);
    console.log("save functions");
    console.log(props);

    setLoading(true);
    let obj = _.cloneDeep(products);
    if (obj[props.name].provider == "shelter") {
      openASTModal();
    }
    if (obj[props.name] && obj[props.name].rateData && !obj[props.name].rateData.raw["Elite"]) {
      let newPlan = {};
      newPlan.id = uuidv4();
      newPlan.name = props.name;
      newPlan.provider = checkMecHasStopLoss(obj)
        ? obj[props.name].provider === "ihp" || obj[props.name].provider === "ihp_agent"
          ? obj[props.name].fields[2].value
          : obj[props.name].provider
        : "Self-Funded";
      newPlan.plan = obj[props.name].plan;
      newPlan.rateData = obj[props.name].rateData;
      newPlan.fields = obj[props.name].fields;
      newPlan.benefits = obj[props.name].benefits;
      newPlan.brokerFee = brokerFee;
      newPlan.addons = isAddonProduct ? _.cloneDeep(addons) : [];

      console.log("save functionality for new plans");
      console.log(newPlan);

      saveProduct(newPlan);
      setHasSubmit(false);
    } else {
      let arr = [];
      Object.entries(obj[props.name].rateData.raw).forEach((entry) => {
        let key = entry[0];
        let value = entry[1];
        let newPlan = {};
        newPlan.id = uuidv4();
        newPlan.name = props.name;
        newPlan.provider = obj[props.name].provider;
        newPlan.plan = obj[props.name].plan;
        newPlan.rateData = {
          cleaned: {
            fiftyPlus: value.response.fiftyPlus,
            underForty: value.response.underForty,
            fortyToFortyNine: value.response.fortyToFortyNine,
            composite: value.response.composite,
          },
          inputWithDescs: value.request,
          raw: value.response,
          continue: true,
          warnings: value.response.warnings,
        };
        newPlan.fields = convertInputsToFields(value.request);
        newPlan.benefits = obj[props.name].benefits;
        newPlan.brokerFee = brokerFee;
        newPlan.addons = isAddonProduct ? _.cloneDeep(addons) : [];
        newPlan.request = value.request;
        newPlan.level = key;
        arr.push(newPlan);
      });
      console.log("save functionality for arrr");
      console.log(arr);
      saveProducts(arr);
    }
    setLoading(false);
  };

  /* Check specific to breckpoint mec to adjust what carrier is saved */
  const checkMecHasStopLoss = (obj) => {
    if (obj[props.name].provider === "breckpoint_mec") {
      let hasStopLoss = obj[props.name].fields.filter((x) => x.name === "addStopLoss")[0].value.desc === "Yes";
      return hasStopLoss;
    }
    return true;
  };

  // Saves the plan configurations for the Quick Quote feature
  const saveQQs = (addons, data) => {
    console.log("Saving Quick Quotes");
    setShowAddons(false);
    setLoading(true);
    let obj = _.cloneDeep(products);
    let arr = [];
    Object.entries(data).forEach((entry) => {
      let key = entry[0];
      let value = entry[1];
      let newPlan = {};
      newPlan.id = uuidv4();
      newPlan.name = props.name;
      newPlan.provider = value.carrier;
      newPlan.plan = "combined_benefit";
      newPlan.rateData = {
        cleaned: {
          fiftyPlus: value.fiftyPlus,
          underForty: value.underForty,
          fortyToFortyNine: value.fortyToFortyNine,
          composite: value.composite,
        },
        inputWithDescs: value,
        raw: value,
        continue: true,
        warnings: value.warnings,
      };
      newPlan.fields = convertInputsToFields(value.request);
      newPlan.benefits = "No benefits";
      newPlan.brokerFee = brokerFee;
      newPlan.addons = isAddonProduct ? _.cloneDeep(addons) : [];
      newPlan.request = value;
      newPlan.level = key;
      arr.push(newPlan);
    });
    saveProducts(arr);
    setLoading(false);
    console.log("Quick Quotes Saved");
  };

  // Saves the plan configurations for the Quick Quote feature
  const saveIHPQQs = (addons, data) => {
    console.log("Saving IHP Quick Quotes");
    setShowAddons(false);
    setLoading(true);
    let obj = _.cloneDeep(products);
    let arr = [];
    Object.entries(data).forEach((entry) => {
      let key = entry[0];
      let value = entry[1];
      let newPlan = {};
      newPlan.id = uuidv4();
      newPlan.name = props.name;
      newPlan.provider = value.carrier;
      newPlan.plan = "ihp";
      newPlan.rateData = {
        cleaned: value,
        inputWithDescs: value,
        raw: value,
        continue: true,
        warnings: value.warnings,
      };
      newPlan.fields = convertInputsToFields(value.request);
      newPlan.benefits = "No benefits";
      newPlan.brokerFee = brokerFee;
      newPlan.addons = isAddonProduct ? _.cloneDeep(addons) : [];
      newPlan.request = value;
      newPlan.level = key;
      arr.push(newPlan);
    });
    saveProducts(arr);
    setLoading(false);
    console.log("IHP Quick Quotes Saved");
  };

  const convertInputsToFields = (inputs) => {
    let arr = [];
    Object.entries(_.omit(inputs, "requestId")).forEach((input) => {
      arr.push({
        name: input[0],
        value: input[1],
        desc: getDescFromName(input[0]),
      });
    });
    return arr;
  };

  const getDescFromName = (name) => {
    let str = "";
    typesArray.forEach((type) => {
      if (name === type.name) str = type.desc;
    });
    return str;
  };

  //Checks to see if a product has already been saved or not.
  const checkDuplicate = (product) => {
    let obj = _.cloneDeep(products);
    let arr = obj[props.name].saved ? obj[props.name].saved : [];
    let isDuplicate = false;
    if (product) {
      arr.forEach((object) => {
        let obj1 = {
          name: object.name,
          provider: object.provider,
          brokerFee: object.brokerFee,
          plan: object.plan,
          fields: object.fields,
          addons: object.addons,
        };
        let obj2 = {
          name: product.name,
          provider: product.provider,
          brokerFee: product.brokerFee,
          plan: product.plan,
          fields: product.fields,
          addons: product.addons,
        };
        if (_.isEqual(obj1, obj2)) {
          isDuplicate = true;
        }
      });
    }
    return isDuplicate;
  };

  /*NOTE:
    Calculates progress based on the ratio of the fields with values and the total number of fields. This takes the carrier and plan fields
    into account which the user doesn't always see but I doubt the user will check our math based on their screen.
     */
  const calculateProgress = () => {
    let answered = 0;
    if (provider !== "") answered = answered + 1;
    if (plan !== "") answered = answered + 1;
    if (!_.isUndefined(fields)) {
      fields.forEach((field) => {
        if (field.value !== undefined) {
          answered = answered + 1;
        }
      });
    }
    let fieldsTotal = !_.isUndefined(fields) ? fields.length : 18;
    let answeredRatio = (answered / (fieldsTotal + 2)) * 0.9; //Adding 2 for the partner and plan fields. All field hold the same amount of progress percentage.
    if (products[props.name].saved && products[props.name].saved.length > 0) {
      answeredRatio = answeredRatio + 0.1;
    }
    setProgress(answeredRatio);
  };

  const openQQModal = () => {
    setOpenQuickQuoteModal(true);
  };

  const openIHPQQModal = () => {
    setOpenIHPQuickQuoteModal(true);
  };

  const openASTModal = () => {
    setOpenASTWarningModal(true);
  };

  const checkIsGap = () => {
    const check = products.hasOwnProperty("gap");
    setIsGap(check);
  };

  const quickQuote = async () => {
    setOpenQuickQuoteModal(false);
    setLoading(true);
    const addons = [];
    const updatedPayloadData = {
      quickQuoteDeductible: quickQuoteDeductible,
      quickQuoteCombinedBenefit: quickQuoteCombinedBenefit,
      census: gapCensus,
      censusTotal: total,
      sicCode: sicCode,
      groupEffectiveDate: groupEffectiveDate,
      state: state,
    };

    // This is here to test for a single provider. Just uncomment and replace with providerList within the getQuickQuotes call below
    //const providerListTest = ["chubb"];
    const providerList = [];

    for (let prov in providers) {
      const providerSend = providers[prov].value.label;
      providerList.push(providerSend);
    }

    await getQuickQuotes(providerList, restConfig.headers, {
      updatedPayloadData,
      setSheltPayload,
      setNationPayload,
      setZurPayload,
      setMarkelPayload,
      setChubbPayload,
      setEverest_gapPayload,
      setQQ,
    }).then((requestData) => saveQQs(addons, requestData));
    setLoading(false);
  };

  console.log("TESTING USE STATES");

  console.log(shelterPayloadTracker);

  console.log(nationPayloadTracker);

  console.log(zurichPayloadTracker);

  console.log(markelPayloadTracker);

  console.log(chubbPayloadTracker);

  console.log(everest_gapPayloadTracker);

  const quickQuoteIHP = async () => {
    console.log("check1");
    setOpenIHPQuickQuoteModal(false);
    console.log("check2");
    setLoading(true);
    console.log("check3");
    const addons = [];
    console.log("check4");
    const updatedPayloadData = {
      quickQuoteDeductible: ihpBrokerFee,
      census: gapCensus,
      censusTotal: total,
      sicCode: sicCode,
      groupEffectiveDate: groupEffectiveDate,
      state: state,
    };
    console.log("check5");

    await getIHPQuickQuotes(restConfig.headers, {
      updatedPayloadData,
    }).then((requestData) => saveIHPQQs(addons, requestData));
    console.log("check6");
    setLoading(false);
    console.log("check7");
  };
  console.log("check8");

  const options = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  return (
    <Slide right duration={750}>
      <Paper className={classes.paper} elevation={5}>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          spacing={1}
          style={{ opacity: props.editing ? ".15" : "1" }}
        >
          <Grid item xs={2}>
            {
              //BUTTON 2
              <>
                {isGap && activeProduct === "gap" ? (
                  <Button
                    style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                    color={"primary"}
                    fullWidth
                    variant={"contained"}
                    onClick={openQQModal}
                  >
                    Quick Quote
                  </Button>
                ) : null}
                {activeProduct === "ihp" && userRole.includes("ihp_agent") ? (
                  <Button
                    style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                    color={"primary"}
                    fullWidth
                    variant={"contained"}
                    onClick={openIHPQQModal}
                  >
                    IHP Quick Quote
                  </Button>
                ) : null}
              </>
            }
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" component="h2" align={"center"}>
              {prettifyProduct(props.name)} PLAN
              <IconButton color={"#0C2659"} className={classes.icon} onClick={() => setOpenInfo(true)}>
                <InfoOutlinedIcon style={{ color: "#0C2659" }} />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {walking && <CircularProgress thickness={4} />}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {(providers.length > 1 || props.name.toLowerCase() === "gap") && (
            <>
              <Grid item xs={6} container alignContent={"center"} alignItems={"center"}>
                <Grid item xs={10} md={11}>
                  <Typography variant="subtitle1" style={{ textAlign: "left", color: "#0C2659" }}>
                    Carrier
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  {/* <Tooltip title={'Please select a carrier. If you select "No Carrier Preference", all plans that are available for your selected options will be given to you.'} placement={'top'}> */}
                  {props.name.toLowerCase() === "gap" && (
                    <Tooltip title={"Please select a carrier."} placement={"top"}>
                      <IconButton>
                        <InfoIcon style={{ color: "#0C2659" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ opacity: props.editing ? "0" : "1" }}>
                <Select
                  value={provider}
                  onChange={(x) => setProvider(x)}
                  options={providers}
                  placeholder={"Select a carrier"}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {plans.length > 1 && provider !== "" && provider.label !== "No Carrier Preference" && (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle1" style={{ textAlign: "left", color: "#0C2659" }}>
                  Plan
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ opacity: props.editing ? "0" : "1" }}>
                <Select value={plan} onChange={(x) => setPlan(x)} options={plans} placeholder={"Select a plan"} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
        {plan !== "" && provider !== "" && (
          <>
            {provider.label !== "No Carrier Preference" &&
              !_.isUndefined(fields) &&
              _.map(fields, (field) => {
                // if (field.name === "coinsuranceOutpatientBenefit" && !hasTriggered) {
                //   console.log("coinsurancePercent field found");
                //   handleCoinsuranceBenefit();
                //   setHasTriggered(true);
                // }
                return (
                  <React.Fragment key={field.name}>
                    {field.name !== "groupEffectiveDate" &&
                      field.name !== "zipCode" &&
                      field.name !== "sicCode" &&
                      !(
                        props.name.toLowerCase() === "gap" &&
                        provider.value.label === "everest_gap" &&
                        (field.name === "policyRenewalDate" || field.name === "coinsuranceOutpatientBenefit")
                      ) && (
                        <Field
                          field={field}
                          walking={walking}
                          product={props.name}
                          provider={provider.value.label}
                          fields={fields}
                        />
                      )}
                  </React.Fragment>
                );
              })}
          </>
        )}
        {provider.value && provider.value === "No Carrier Preference" && (
          <NoPreferenceControl
            {...props}
            mapFields={mapFields}
            setProgress={setProgress}
            setNoPreferenceDisabled={setNoPreferenceDisabled}
            setNoPlansAvailable={setNoPlansAvailable}
            noPreferenceDisabled={noPreferenceDisabled}
          />
        )}
        {progress >= 0.9 &&
          (props.name === "mec" ||
            props.name === "emec" ||
            props.name === "major_medical" ||
            props.name === "everest" ||
            props.name === "extras" ||
            props.name === "dental" ||
            props.name === "ihp") && (
            <Grid item container justify={"center"} alignItems={"center"} spacing={1}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" align={"left"} color={"primary"}>
                  Broker Fee ($ Amount)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.name === "ihp" ? (
                  <TextField
                    margin={"dense"}
                    variant={"outlined"}
                    fullWidth
                    value={brokerFee}
                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                    onChange={(e) => setBrokerFee(e.target.value)}
                    type={"number"}
                  />
                ) : (
                  <TextField
                    margin={"dense"}
                    variant={"outlined"}
                    fullWidth
                    value={brokerFee}
                    onChange={(e) => setBrokerFee(e.target.value)}
                    type={"number"}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          )}
        {benefits && benefits !== "" && benefits.response && (
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={6}>
              <Typography variant={"subtitle1"} color={"primary"}>
                Benefits
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"body2"} className={classes.link} onClick={() => setBenefitsOpen(true)}>
                {benefits.response.desc ? benefits.response.desc : "View all benefits"}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Divider />
        <CardActions>
          <Grid container item xs={12} spacing={2}>
            {/* HSA Compatibility Selection - Only show for GAP products */}
            {rateData.ratesAvailable && props.name.toLowerCase() === "gap" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align={"left"} color={"primary"}>
                    HSA Compatible *
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={options.find((option) => option.value === hsaCompatibility)}
                    onChange={(selectedOption) => setHsaCompatibility(selectedOption.value)}
                    options={
                      fields?.find((field) => field.name === "deductible")?.value < 1650
                        ? [{ value: false, label: "No" }] // Only "No" is available
                        : options // Both "Yes" and "No" are available
                    }
                    placeholder="Select HSA compatibility"
                  />
                  {hsaCompatibility === undefined && (
                    <Typography variant="caption" color="error">
                      HSA compatibility selection is required
                    </Typography>
                  )}
                </Grid>
              </>
            )}

            {/* Save Button */}
            <Grid item xs={12}>
              <Grid container justify={"center"}>
                <Grid item xs={6}>
                  {rateData.ratesAvailable &&
                    plan !== "" &&
                    provider !== "" &&
                    provider.label !== "No Carrier Preference" && (
                      <Button
                        style={{ backgroundColor: "#0C2659", color: "#FFFFFF" }}
                        color={"primary"}
                        fullWidth
                        variant={"contained"}
                        onClick={() => preSave()}
                        disabled={
                          (products[props.name].saved && products[props.name].saved.length > 11) ||
                          (props.name.toLowerCase() === "gap" && hsaCompatibility === undefined) // Only check HSA for GAP products
                        }
                      >
                        Save plan
                      </Button>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </Paper>
      <InfoModal
        open={openInfo}
        close={() => setOpenInfo(false)}
        {...productDescriptionMapping[prettifyProduct(props.name).toLowerCase()]}
        name={prettifyProduct(props.name)}
      />
      {
        //BUTTON!!!
        <>
          <QuickQuoteModal
            open={openQuickQuoteModal}
            quickQuote={quickQuote}
            close={() => setOpenQuickQuoteModal(false)}
          />
          <IHPQuickQuoteModal
            open={openIHPQuickQuoteModal}
            quickQuoteIHP={quickQuoteIHP}
            setBrokerFee={setBrokerFee}
            close={() => setOpenIHPQuickQuoteModal(false)}
          />
        </>
      }

      <ShelterWarningModal open={openASTWarningModal} close={() => setOpenASTWarningModal(false)} />
      <RatesModal
        open={openRates}
        close={() => setOpenRates(false)}
        json={rateData.cleaned}
        name={`${_.upperFirst(props.name)} Rates`}
      />
      <RatesModal
        open={benefitsOpen && benefits !== ""}
        close={() => setBenefitsOpen(false)}
        json={benefits ? benefits.response : benefits}
        name={"Benefit Details"}
      />
      <GeneralModal
        title={"No Plans Available"}
        open={noPlansAvailable}
        close={() => setNoPlansAvailable(false)}
        content={
          "There are no available plans for the selected inputs. Please select different options and try again. If the problem persists, please contact support."
        }
      />
      <AddonsModal
        productType={props.name.toLowerCase()}
        open={showAddons}
        onSave={save}
        pastSelections={isAddonProduct && !_.isUndefined(props.addons) ? props.addons : []}
      />
    </Slide>
  );
}
